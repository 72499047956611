import * as React from 'react';
import { Component } from 'react';
import { Row } from 'antd-min';

interface GLPageTitleProps {
    title?: string
    className?: string
}

export const GLPageTitle: React.StatelessComponent<GLPageTitleProps> = (props) => {
    return <Row className={props.className}> <h2> {props.title} </h2> </Row>
}