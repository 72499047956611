import * as React from 'react'
import { Component } from 'react';
import { Modal } from 'antd-min';
import { ModalProps } from 'antd/lib/modal/Modal'

interface GLModalProps extends ModalProps {
    content?: JSX.Element[]
}

interface ModalStates { }

export class GLModal extends Component<GLModalProps, ModalStates> {
    constructor(props) {
        super(props);
    }
    render() {
        const { okText, cancelText } = this.props;
        return (
            <Modal
                wrapClassName="vertical-center-modal"
                okText="OK"
                cancelText="Cancel"
                {...this.props}
            >
                {this.props.content}
            </Modal>
        )
    }
}