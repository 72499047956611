import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import {
    withRouter,
    GLFormComponentProps,
    GLUtil,
    HttpStatusCode,
    MessageHelper,
    GLGlobal,
    connect,
    StateType
} from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import {
    lazyInject,
    TYPES,
    CONSTS,
    SchoolHelper,
    fmtMsg
} from "@app/util/index";
import { ISchoolService } from "@app/service/schools/index";
import { SchoolDetail, SchoolDetailFields } from "./components/schooldetail";
import { IRegionService } from "@app/service/admin/regions/index";
import { GLPageTitle } from "@app/components/admin/gl-pagetitle";
import { GSAdminLocale } from "@app/locales/localeid";
import { message } from "antd-min";

interface SchoolsCreatePageProps extends RouteComponentProps<any> { }
interface SchoolsCreatePageStates extends SchoolDetailFields { }


export class SchoolsCreatePage extends Component<
    SchoolsCreatePageProps & GLFormComponentProps,
    SchoolsCreatePageStates
> {
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;
    @lazyInject(TYPES.ISchoolService)
    service: ISchoolService;
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataLoading: true,
            dataSaving: false,
            dataDeleting: false,
            onSaveSchool: this.handleSaveSchool.bind(this)
        };
    }
    componentDidMount() {
        const schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType(false);
        const schoolSubscriptionTypeUsage = SchoolHelper.generateSchoolSubscriptionTypeUsage();
        const schoolUnit = SchoolHelper.generateSchoolUnit();
        this.regionService
            .getItemsBy()
            .then(data => {
                this.setState({
                    dataLoading: false,
                    deletable: false,
                    regions: data.data,
                    defaultRegion: SchoolHelper.getDefaultRegion(),
                    defaultSubscriptionType: schoolSubscriptionType
                        .keys()
                        .next().value,
                    defaultSubscriptionTypeUsage: schoolSubscriptionTypeUsage
                        .keys()
                        .next().value,
                    defaultUnit: schoolUnit.keys().next().value,
                    defaultAvatar: ""
                });
            })
            .catch(response => {
                //console.log(response);
                this.setState({ dataSaving: false });
            });
    }
    handleSaveSchool(err, values) {
        if (!err) {
            if (values.maxUnit == 0 && values.lsMaxUnit == 0) {
                message.error(
                    fmtMsg({
                        id: GSAdminLocale.SettingErrorNoMaxUnitSelected
                    })
                );
            } else if ((values.allowGSMaxUnit && !values.grapeSEEDLicenses.length) || (values.allowLSMaxUnit && !values.littleSEEDLicenses.length)) {
                message.error(
                    fmtMsg({
                        id: GSAdminLocale.SettingErrorNoLicenseTypeSelected
                    })
                );
            } else {
                this.setState({ dataSaving: true });
                this.service
                    .createSchool(values)
                    .then(result => {
                        const regionId = this.props.match.params.regionId;
                        this.setState({ dataSaving: false });
                        this.props.history.push({
                            pathname: GLUtil.pathStringify(PathConfig.Region, {
                                regionId: regionId
                            })
                        });
                    })
                    .catch(error => {
                        //console.log(error);
                        this.setState({ dataSaving: false });
                    });
            }
        }
    }
    render() {
        return (
            <div className="school-page">
                <GLPageTitle
                    title={GLGlobal.intl.formatMessage({
                        id: GSAdminLocale.SchoolModelTitle
                    })}
                />
                <SchoolDetail
                    isShowCampus={true}
                    regionId={this.props.match.params.regionId}
                    {...this.state}
                    onCancel={() =>
                        this.props.history.push({
                            pathname: GLUtil.pathStringify(PathConfig.Region, {
                                regionId: this.props.match.params.regionId
                            })
                        })
                    }
                />
            </div>
        );
    }
}
