import * as React from "react";
import { Component } from "react";
import { Form, Row, Col, Select, Input, Button, Checkbox, Card, Spin, Icon, Upload, message, Modal, InputNumber } from "antd-min";
import {
    GLGlobal,
    GLLocale,
    GLForm,
    FormHelper,
    ComparisonOperator,
    GLFormComponentProps,
    FormItemsLayout,
    InvitationType,
    alignPop,
    withRouter,
    connect
} from "gl-commonui";
import {
    CommonHelper,
    SchoolHelper,
    extendForm,
    lengthValider,
    fmtMsg,
    CONSTS,
    AppLockState,
    lazyInject,
    TYPES,
    GSAdminAction,
    LicenseTypes,
    deepClone,
    LicenseTypeNameValueMap,
    subscriptionTypeUsage
} from "@app/util/index";
import { GSAdminLocale, LicenseTypeLocale, SchoolLocale } from "@app/locales/localeid";
import { GLModal } from "@app/components/admin/gl-modal";
import { RegionModel } from "@app/service/admin/regions/index";
import {
    SchoolDetailModelPropNames,
    CampusPropNames,
    ISchoolService
} from "@app/service/schools/index";

import "./schooldetail.less";
import { SubmitBtns, SchoolNotes } from "@app/components";
import { FormattedMessage } from "react-intl";
import { Models } from "@azure/storage-blob";
import { RouteComponentProps } from "react-router";
import { TagEntity, TagListModel } from "@app/service/tag-manager";
import { StateType } from "@app/states";
import { fetchTagListAction } from "@app/states/tag-manager/tag-manager";
import { isNull } from "lodash";
import { SubscriptionType } from "@app/service/class";

const { TextArea } = Input;

export interface SchoolDetailFields {
    regions?: RegionModel[];
    defaultName?: string;
    defaultRegion?: RegionModel;
    defaultEnglishName?: string;
    defaultGSNumber?: string;
    defaultSubscriptionType?: string;
    defaultSubscriptionTypeUsage?: string;
    defaultUnit?: string;
    defaultAvatar?: string;
    defaultDisabled?: boolean;
    dataLoading?: boolean;
    dataSaving?: boolean;
    dataDeleting?: boolean;
    deletable?: boolean;
    isEdit?: boolean;
    regionDisabled?: boolean;
    defaultNotes?: string;
    onSaveSchool?: (err, values) => void;
    onDeleteSchool?: () => void;
    onCancel?: () => void;
}

interface SchoolDetailProps extends GLFormComponentProps, SchoolDetailFields {
    history?: any;
    isShowCampus?: boolean;
    regionId?: string;
    tagList?: { [key: string]: TagListModel[] };
    fetchTagListAction?: typeof fetchTagListAction;
}

interface SchoolDetailStates {
    regionOptions?: JSX.Element[] | null;
    subscriptionOptions?: JSX.Element[] | null;
    subscriptionTypeUsageOptions?: JSX.Element[] | null;
    unitOptions?: JSX.Element[] | null;
    avatarFile?: any;
    avatarImage?: string;
    deletePromptModalVisible: boolean;
    lsUnitOptions?: JSX.Element[] | null;
    isGSMaxUnit: boolean;
    isLSMaxUnit: boolean;
    currentGSMaxUnit: number;
    currentLSMaxUnit: number;
    schoolCode?: number;
    enableCodeLogin: boolean;
    currentGSLicenses: number[] | [];
    currentLSLicenses: number[] | [];
}

const { renderFormItem } = FormHelper;
const Dragger = Upload.Dragger;
const defaultGSUnit = 1;
const LSClassic = "LSClassic";
const GSClassic = "GSClassic";
const GSConnect = "GSConnect";
const GSNexus = "GSNexus";
const GSHybridConnect = "GSHybridConnect";
const GSHybridNexus = "GSHybridNexus";
const GSHybridConnectNexus = "GSHybridConnectNexus";

@GLForm.create()
@withRouter
@connect(({ tagManager: { tagList } }: StateType) => ({ tagList }), {
    fetchTagListAction
})
export class SchoolDetail extends Component<
SchoolDetailProps,
SchoolDetailStates
> {
    @lazyInject(TYPES.ISchoolService)
    service: ISchoolService;

    private appLockStateOptions?: JSX.Element[];
    constructor(props) {
        super(props);
        this.state = {
            subscriptionOptions: this.getSubscriptionOptions(),
            subscriptionTypeUsageOptions: this.getSubscriptionTypeUsageOptions(),
            unitOptions: this.getUnitOptions(),
            avatarFile: [],
            deletePromptModalVisible: false,
            lsUnitOptions: this.getLSUnitOptions(),
            isGSMaxUnit: true,
            isLSMaxUnit: true,
            currentGSMaxUnit: defaultGSUnit,
            enableCodeLogin: false,
            schoolCode: 0,
            currentLSMaxUnit: CONSTS.LittleSEEDMaxUnit,
            currentGSLicenses: [],
            currentLSLicenses: [],
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getAvatarUploadInfo = this.getAvatarUploadInfo.bind(this);
        this.removeSchoolAvatar = this.removeSchoolAvatar.bind(this);
        this.confirmDeleteItem = this.confirmDeleteItem.bind(this);
    }
    componentDidMount = () => {
        this.service.generateSchoolCode(this.props.regionId)
            .then(data => {
                this.setState({ enableCodeLogin: data.enableCodeLogin, schoolCode: data.schoolCode });
            })
            .catch(err => {
                console.log(err);
            });
        if (this.props.regionId) {
            this.props.fetchTagListAction({ regionId: this.props.regionId, entity: TagEntity.School });
        }
    }
    getOption(key, value, text) {
        return (
            <Select.Option key={key} value={value}>
                {text}
            </Select.Option>
        );
    }
    getRegionOptions(props) {
        if (props.isEdit) {
            return [
                this.getOption(
                    1,
                    props.defaultRegion.id,
                    props.defaultRegion.name
                )
            ];
        } else {
            return props.regions.map((region, index) => {
                return this.getOption(index, region.id, region.englishName);
            });
        }
    }
    getSubscriptionOptions() {
        const schoolSubscriptionType = SchoolHelper.generateSchoolSubscriptionType(false);
        return [...schoolSubscriptionType.keys()].map((typeKey, index) => {
            return this.getOption(
                index,
                typeKey,
                schoolSubscriptionType.get(typeKey)
            );
        });
    }
    getSubscriptionTypeUsageOptions() {
        const schoolSubscriptionTypeUsage = SchoolHelper.generateSchoolSubscriptionTypeUsage();
        return [...schoolSubscriptionTypeUsage.keys()].map((typeKey, index) => {
            return this.getOption(
                index,
                typeKey,
                schoolSubscriptionTypeUsage.get(typeKey)
            );
        });
    }
    getUnitOptions() {
        const schoolUnit = SchoolHelper.generateSchoolUnit();
        return [...schoolUnit.keys()].map((unitKey, index) => {
            return this.getOption(index, unitKey, schoolUnit.get(unitKey));
        });
    }
    getLSUnitOptions() {
        const schoolUnit = SchoolHelper.generateLSSchoolUnit();
        return [...schoolUnit.keys()].map((unitKey, index) => {
            return this.getOption(index, unitKey, schoolUnit.get(unitKey));
        });
    }

    gsMaxUnitOnChange(e) {
        const { resetFields } = this.props.form;
        resetFields(["maxUnit"]);
        resetFields([GSClassic, GSConnect, GSNexus, GSHybridConnect, GSHybridNexus, GSHybridConnectNexus]);
        this.setState({
            isGSMaxUnit: e.target.checked,
            currentGSMaxUnit: e.target.checked ? defaultGSUnit : 0,
            currentGSLicenses: []
        });
    }

    lsMaxUnitOnChange(e) {
        const { resetFields } = this.props.form;
        resetFields(["lsMaxUnit"]);
        resetFields([LSClassic]);
        this.setState({
            isLSMaxUnit: e.target.checked,
            currentLSMaxUnit: e.target.checked ? CONSTS.LittleSEEDMaxUnit : 0,
            currentLSLicenses: []
        });
    }

    getAvatarUploadInfo() {
        return {
            action: "",
            accept: "image/*",
            onRemove: file => {
                this.setState(({ avatarFile }) => {
                    const index = avatarFile.indexOf(file);
                    const newFileList = avatarFile.slice();
                    newFileList.splice(index, 1);
                    return {
                        avatarFile: [],
                        avatarImage: ""
                    };
                });
            },
            beforeUpload: file => {
                const extValid =
                    file.type == "image/png" ||
                    file.type == "image/gif" ||
                    file.type == "image/jpeg";
                if (!extValid) {
                    message.error(
                        fmtMsg({ id: GSAdminLocale.SchoolModelImageType })
                    );
                    return false;
                }
                const isLt100K = file.size / 1024 < 100;
                if (!isLt100K) {
                    message.error(
                        fmtMsg({ id: GSAdminLocale.SchoolModelImageSize })
                    );
                    return false;
                }
                this.setState(({ avatarFile }) => ({
                    avatarFile: [...avatarFile, file]
                }));
                CommonHelper.getImageBase64(file, imageUrl => {
                    this.setState({ avatarImage: imageUrl });
                });
                return false;
            },
            fileList: this.state.avatarFile
        };
    }
    removeSchoolAvatar() {
        this.setState({
            avatarFile: [],
            avatarImage: ""
        });
    }
    componentWillReceiveProps(nextProps) {
        if (
            this.props.defaultRegion !==
            (nextProps.defaultRegion as RegionModel)
        ) {
            this.setState({ regionOptions: this.getRegionOptions(nextProps) });
        }
        if (
            this.props.defaultAvatar !== nextProps.defaultAvatar &&
            nextProps.defaultAvatar
        ) {
            this.setState({ avatarImage: nextProps.defaultAvatar });
        }
    }
    checkUniqueness = (rule: any, value: any, callback: (message?: string) => void) => {
        if (value && value > 0) {
            this.service.checkSchoolCodeUniqueness({ code: value }, null)
                .then(data => {
                    if (!data) {
                        callback(fmtMsg(GSAdminLocale.SchoolModelCodeError));
                        return;
                    }
                    callback();
                }).catch(err => {
                    callback(fmtMsg(GSAdminLocale.SchoolModelCodeError));
                    return;
                });
        } else {
            callback();
        }
    }

    keyValueMap(value) {
        switch(value) {
            case GSClassic: 
            case LSClassic:
                return LicenseTypes.Classic;
            case GSConnect:return LicenseTypes.Connect;
            case GSNexus: return LicenseTypes.Nexus;
            case GSHybridConnect: return LicenseTypes.ClassicConnectHybrid;
            case GSHybridNexus: return LicenseTypes.ClassicNexusHybrid;
            case GSHybridConnectNexus: return LicenseTypes.ConnectNexusHybrid;
        }
    }
    getGSLicenseTypeKeys(values) {
        let keys = Object.keys(values);
        keys = keys.filter(k => k === GSClassic || k === GSConnect || k === GSNexus || k === GSHybridConnect || k === GSHybridNexus || k === GSHybridConnectNexus);
        let updatedGSLicenseTypes = [];
        keys.forEach(key => {
            if (values[key]) {
                updatedGSLicenseTypes.push(this.keyValueMap(key))
            }
        })
        return updatedGSLicenseTypes;
    }
    getLSLicenseTypeKeys(values) {
        let keys = Object.keys(values);
        keys = keys.filter(k => k === LSClassic);
        let updatedLSLicenseTypes = [];
        keys.forEach(key => {
            if (values[key]) {
                updatedLSLicenseTypes.push(this.keyValueMap(key))
            }
        })
        return updatedLSLicenseTypes;
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            const submit = () => {
                values.grapeSEEDLicenses = this.getGSLicenseTypeKeys(values);
                values.littleSEEDLicenses = this.getLSLicenseTypeKeys(values);
                values.subscriptionType = SubscriptionType.Digital;
                values.subscriptionTypeUsage = subscriptionTypeUsage.Dual;
                values.image = this.state.avatarImage
                    ? this.state.avatarImage
                    : "";
                const { onSaveSchool } = this.props;
                if (onSaveSchool) onSaveSchool(err, values);
            };
            if (
                this.props.isEdit &&
                (values.subscriptionType !==
                    this.props.defaultSubscriptionType ||
                    values.subscriptionTypeUsage !==
                    this.props.defaultSubscriptionTypeUsage)
            ) {
                const fmt = GLGlobal.intl.formatMessage;
                Modal.confirm({
                    onOk: submit,
                    iconType: "question-circle-o",
                    content: fmt({ id: GSAdminLocale.SchoolModelChangeSubType })
                });
            } else {
                submit();
            }
        });
    }
    onDelete(e) {
        this.setState({ deletePromptModalVisible: true });
    }
    confirmDeleteItem() {
        const { onDeleteSchool } = this.props;
        this.setState({ deletePromptModalVisible: false });
        if (onDeleteSchool) onDeleteSchool();
    }
    renderDeleteModal() {
        return (
            <GLModal
                title={fmtMsg({ id: GSAdminLocale.SchoolModelDelSchool })}
                content={[
                    <p key={1}>
                        {" "}
                        {fmtMsg({
                            id: GSAdminLocale.SchoolModelSureDelSchool
                        })}{" "}
                    </p>
                ]}
                visible={this.state.deletePromptModalVisible}
                onOk={this.confirmDeleteItem}
                onCancel={() =>
                    this.setState({ deletePromptModalVisible: false })
                }
            />
        );
    }
    onChange(value) {
        const selectedRegion = this.props.regions.find(
            region => region.id == value
        );
        SchoolHelper.saveDefaultRegion(selectedRegion);
    }

    onGSLicenseTypeChange = (e) => {
        const fieldName: string = e.target.id;  // gsClassic
        const prefix = fieldName.slice(0, 2);   // gs
        const suffix = fieldName.slice(2, fieldName.length) // Classic

        const selectedGSLicenseType = LicenseTypeNameValueMap[suffix];

        let currentGSLicenses = []
        if (isNull(this.state.currentGSLicenses)) {
            currentGSLicenses.push(selectedGSLicenseType);
            this.setState({ currentGSLicenses: currentGSLicenses });
            return;
        }

        currentGSLicenses = deepClone(this.state.currentGSLicenses);
        const hasLicense = currentGSLicenses && currentGSLicenses.some(l => l === selectedGSLicenseType);
        if (hasLicense) {
            currentGSLicenses = currentGSLicenses.filter(l => l !== selectedGSLicenseType);
        }
        else {
            currentGSLicenses.push(selectedGSLicenseType);
        }

        this.setState({ currentGSLicenses: currentGSLicenses });
    }

    onLSLicenseTypeChange = (e) => {
        const fieldName: string = e.target.id;
        const prefix = fieldName.slice(0, 2);
        const suffix = fieldName.slice(2, fieldName.length)

        const selectedLSLicenseType = LicenseTypeNameValueMap[suffix];

        let currentLSLicenses = [];
        if (isNull(this.state.currentLSLicenses)) {
            currentLSLicenses.push(selectedLSLicenseType);
            this.setState({ currentLSLicenses: currentLSLicenses });
            return;
        }

        currentLSLicenses = deepClone(this.state.currentLSLicenses);
        const hasLicense = currentLSLicenses && currentLSLicenses.some(l => l === selectedLSLicenseType);
        if (hasLicense) {
            currentLSLicenses = currentLSLicenses.filter(l => l !== selectedLSLicenseType);
        }
        else {
            currentLSLicenses.push(selectedLSLicenseType);
        }

        this.setState({ currentLSLicenses: currentLSLicenses });
    }

    render() {
        const form = this.props.form;
        const codeMinVal = CONSTS.MIN_SCHOOL_CODE;
        const codeMaxVal = CONSTS.MAX_SCHOOL_CODE;
        const formatMessage = GLGlobal.intl.formatMessage;
        const avatarUploadInfo = this.getAvatarUploadInfo();
        const avatarSrc = {};
        if (this.state.avatarImage) {
            avatarSrc["src"] = this.state.avatarImage;
        }
        const nameMaxLength = 50;
        const englishNameMaxLength = 50;
        const gsNumberMaxLength = 30;
        const defaultOnsiteCount = 3;
        const defaultLVACount = 2;
        const minCount = 0;
        const maxCount = 99;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" }
        };
        this.appLockStateOptions = SchoolHelper.getDefaultAppLockStateOptions();
        const regionId = this.props.regionId;
        const tags = this.props.tagList[`${regionId}-${TagEntity.School}`] || [];
        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);

        return (
            <Spin
                spinning={this.props.dataLoading}
                wrapperClassName="school-detail-spin"
            >
                <GLForm form={form} onSubmit={this.onSubmit}>
                    <Row type="flex" align="top">
                        <Col xs={24} sm={18} md={18} lg={16}>
                            <FormItemsLayout colTotal={2}>
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelName,
                                    SchoolDetailModelPropNames.name,
                                    <Input></Input>,
                                    this.props.defaultName,
                                    true,
                                    [
                                        FormHelper.ruleForCompareLength(
                                            GSAdminLocale.SchoolModelName,
                                            ComparisonOperator.LessOrEqualsThan,
                                            nameMaxLength
                                        )
                                    ]
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelRegion,
                                    SchoolDetailModelPropNames.regionId,
                                    <Select
                                        size="large"
                                        disabled={true}
                                        onChange={value => this.onChange(value)}
                                        {...alignPop()}
                                    >
                                        {...this.state.regionOptions}
                                    </Select>,
                                    this.props.defaultRegion &&
                                    this.props.defaultRegion.id,
                                    true
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelEnglishName,
                                    SchoolDetailModelPropNames.englishName,
                                    <Input></Input>,
                                    this.props.defaultEnglishName,
                                    false,
                                    [
                                        FormHelper.ruleForCompareLength(
                                            GSAdminLocale.SchoolModelEnglishName,
                                            ComparisonOperator.LessOrEqualsThan,
                                            englishNameMaxLength
                                        )
                                    ]
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelCode,
                                    SchoolDetailModelPropNames.gsNumber,
                                    <Input></Input>,
                                    this.props.defaultGSNumber,
                                    false,
                                    [
                                        FormHelper.ruleForCompareLength(
                                            GSAdminLocale.SchoolModelCode,
                                            ComparisonOperator.LessOrEqualsThan,
                                            gsNumberMaxLength,
                                            false
                                        )
                                    ]
                                )}
                            </FormItemsLayout>

                            <FormItemsLayout colTotal={2}>
                                {hasTagManagerPermission && renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelTags,
                                    "tags",
                                    <Select
                                        size="large"
                                        {...alignPop()}
                                        mode="multiple"
                                        maxTagCount={2}
                                        optionFilterProp="children"
                                    >
                                        {tags.map(tag => (
                                            <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                        ))}
                                    </Select>,
                                    [],
                                    false,
                                    []
                                )}
                                {renderFormItem(
                                    form,
                                    GSAdminLocale.SchoolModelAppLockState,
                                    SchoolDetailModelPropNames.appLockState,
                                    <Select size="large" {...alignPop()}>
                                        {...this.appLockStateOptions}
                                    </Select>,
                                    AppLockState.Unlock,
                                    true
                                )}
                                {this.state.enableCodeLogin && renderFormItem({
                                    form,
                                    localeId: GSAdminLocale.SchoolModelSchoolCode,
                                    fieldName: "code",
                                    formControl: <InputNumber
                                        min={codeMinVal}
                                        max={codeMaxVal}
                                    />,
                                    initialValue: this.state.schoolCode,
                                    required: true,
                                    rules: [{ validator: this.checkUniqueness }],
                                    decoratorOptions: { validateTrigger: "onChange" }
                                })}
                                {!this.props.isShowCampus && (
                                    <Form.Item>
                                        {form.getFieldDecorator(
                                            SchoolDetailModelPropNames.disabled,
                                            {
                                                valuePropName: "checked",
                                                initialValue: this.props
                                                    .defaultDisabled
                                            }
                                        )(
                                            <Checkbox
                                                disabled={
                                                    this.props.regionDisabled
                                                }
                                            >
                                                {formatMessage({
                                                    id:
                                                        GSAdminLocale.SchoolModelDisabled
                                                })}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                )}
                            </FormItemsLayout>

                            <div className="curriculum-details">
                                <div className="curriculum-details__title">
                                    {fmtMsg({
                                        id:
                                            GSAdminLocale.SettingMaxUnitSettingTitle
                                    })}
                                </div>
                                <FormItemsLayout colTotal={2}>
                                    {renderFormItem(
                                        { ...form, ...options },
                                        GSAdminLocale.SettingsAllowGSMaxUnit,
                                        "allowGSMaxUnit",
                                        <Checkbox
                                            onChange={this.gsMaxUnitOnChange.bind(
                                                this
                                            )}
                                        >
                                            <FormattedMessage
                                                id={
                                                    GSAdminLocale.SettingsAllowGSMaxUnit
                                                }
                                            />
                                        </Checkbox>,
                                        this.state.isGSMaxUnit
                                    )}

                                    {renderFormItem(
                                        { ...form, ...options },
                                        GSAdminLocale.SettingsAllowLSMaxUnit,
                                        "allowLSMaxUnit",
                                        <Checkbox
                                            onChange={this.lsMaxUnitOnChange.bind(
                                                this
                                            )}
                                        >
                                            <FormattedMessage
                                                id={
                                                    GSAdminLocale.SettingsAllowLSMaxUnit
                                                }
                                            />
                                        </Checkbox>,
                                        this.state.isLSMaxUnit
                                    )}

                                    {this.state.isGSMaxUnit
                                        ? renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelGSMaxUnit,
                                            "maxUnit",
                                            <Select
                                                size="large"
                                                {...alignPop()}
                                            >
                                                {...this.state.unitOptions}
                                            </Select>,
                                            this.state.currentGSMaxUnit.toString(),
                                            true
                                        )
                                        : renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelGSMaxUnit,
                                            "maxUnit",
                                            <Select
                                                disabled
                                                size="large"
                                                {...alignPop()}
                                            >
                                                {this.getOption(
                                                    "0",
                                                    "0",
                                                    "0"
                                                )}
                                            </Select>,
                                            this.state.currentGSMaxUnit.toString()
                                        )}

                                    {this.state.isLSMaxUnit
                                        ? renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelLSMaxUnit,
                                            "lsMaxUnit",
                                            <Select
                                                size="large"
                                                {...alignPop()}
                                            >
                                                {...this.state.lsUnitOptions}
                                            </Select>,
                                            this.state.currentLSMaxUnit.toString(),
                                            true
                                        )
                                        : renderFormItem(
                                            form,
                                            GSAdminLocale.SchoolModelLSMaxUnit,
                                            "lsMaxUnit",
                                            <Select
                                                disabled
                                                size="large"
                                                {...alignPop()}
                                            >
                                                {this.getOption(
                                                    "0",
                                                    "0",
                                                    "0"
                                                )}
                                            </Select>,
                                            this.state.currentLSMaxUnit.toString()
                                        )}
                                    {
                                        <div className="gsLicenseType">
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.Classic,
                                                    GSClassic,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.Classic}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.Connect,
                                                    GSConnect,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.Connect}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.Nexus,
                                                    GSNexus,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.Nexus}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.HybridConnect,
                                                    GSHybridConnect,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.HybridConnect}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.HybridNexus,
                                                    GSHybridNexus,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.HybridNexus}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.HybridConnectNexus,
                                                    GSHybridConnectNexus,
                                                    <Checkbox 
                                                        disabled={!this.state.isGSMaxUnit}
                                                        onChange={this.onGSLicenseTypeChange.bind(this)} >
                                                        <FormattedMessage id={LicenseTypeLocale.HybridConnectNexus}/>
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                        </div>
                                    }
                                    {
                                        <div className="lsLicenseType" style={{ alignSelf: "start" }}>
                                            {
                                                renderFormItem
                                                (
                                                    { ...form, ...options },
                                                    LicenseTypeLocale.Classic,
                                                    LSClassic,
                                                    <Checkbox
                                                        disabled={!this.state.isLSMaxUnit}
                                                        onChange={this.onLSLicenseTypeChange.bind(this)}
                                                    >
                                                        <FormattedMessage id={LicenseTypeLocale.Classic} />
                                                    </Checkbox>,
                                                    false
                                                )
                                            }
                                        </div>
                                    }
                                </FormItemsLayout>
                            </div>

                            <div className="visitation-details">
                                <div className="visitation-details__title">
                                    {fmtMsg({ id: GSAdminLocale.SettingVisitationDetails })}
                                </div>
                                <FormItemsLayout colTotal={2}>
                                    {renderFormItem(
                                        form,
                                        GSAdminLocale.SettingOnsiteVisitCount,
                                        "onSiteVisitCount",
                                        <InputNumber
                                            min={minCount}
                                            max={maxCount}
                                            precision={0}
                                        />,
                                        defaultOnsiteCount,
                                        true
                                    )}
                                    {renderFormItem(
                                        form,
                                        GSAdminLocale.SettingLVACount,
                                        "lvaCount",
                                        <InputNumber
                                            min={minCount}
                                            max={maxCount}
                                            precision={0}
                                        />,
                                        defaultLVACount,
                                        true
                                    )}
                                </FormItemsLayout>
                            </div>

                            <SchoolNotes
                                form={form}
                                defaultNotes={this.props.defaultNotes}
                            />
                            {this.props.isShowCampus && (
                                <>
                                    <Row className="sub-title">
                                        {fmtMsg({
                                            id:
                                                GSAdminLocale.SchoolModelMainCampus
                                        })}
                                    </Row>
                                    <FormItemsLayout colTotal={2}>
                                        {renderFormItem(
                                            form,
                                            GSAdminLocale.CampusName,
                                            CampusPropNames.name,
                                            <Input />,
                                            null,
                                            true,
                                            lengthValider(
                                                GSAdminLocale.CampusName,
                                                120
                                            )
                                        )}
                                        {renderFormItem(
                                            form,
                                            SchoolLocale.CampusGSNumber,
                                            CampusPropNames.gsNumber,
                                            <Input />,
                                            null,
                                            false,
                                            lengthValider(
                                                SchoolLocale.CampusGSNumber,
                                                100
                                            )
                                        )}
                                        {renderFormItem(
                                            form,
                                            GSAdminLocale.CampusPhone,
                                            CampusPropNames.phone,
                                            <Input />,
                                            null,
                                            true,
                                            lengthValider(
                                                GSAdminLocale.CampusPhone,
                                                30
                                            )
                                        )}
                                        {renderFormItem(
                                            form,
                                            GSAdminLocale.CampusFax,
                                            CampusPropNames.fax,
                                            <Input />,
                                            null,
                                            false,
                                            lengthValider(
                                                GSAdminLocale.CampusFax,
                                                30
                                            )
                                        )}
                                        <div className="campus-form-location">
                                            {renderFormItem(
                                                form,
                                                GSAdminLocale.CampusPostalCode,
                                                CampusPropNames.postalCode,
                                                <Input />,
                                                null,
                                                true,
                                                lengthValider(
                                                    GSAdminLocale.CampusPostalCode,
                                                    30
                                                )
                                            )}
                                        </div>
                                    </FormItemsLayout>
                                    <Row className="campus-address">
                                        {renderFormItem(
                                            extendForm(form),
                                            GSAdminLocale.CampusAddressFull,
                                            CampusPropNames.fullAddress,
                                            <TextArea rows={3} className="address" />,
                                            null,
                                            true,
                                            lengthValider(
                                                GSAdminLocale.CampusAddressFull,
                                                500
                                            )
                                        )}
                                    </Row>
                                </>
                            )}
                        </Col>
                        <Col>
                            <Card
                                style={{ width: 150 }}
                                bodyStyle={{ padding: 0 }}
                                bordered={false}
                                actions={
                                    this.state.avatarImage
                                        ? [
                                            <Upload {...avatarUploadInfo}>
                                                {" "}
                                                <Icon
                                                    type="edit"
                                                    title={formatMessage({
                                                        id:
                                                            GSAdminLocale.SchoolModelChangeAvatar
                                                    })}
                                                />{" "}
                                            </Upload>,
                                            <Icon
                                                type="delete"
                                                title={formatMessage({
                                                    id:
                                                        GSAdminLocale.SchoolModelRemoveAvatar
                                                })}
                                                onClick={
                                                    this.removeSchoolAvatar
                                                }
                                            />
                                        ]
                                        : [
                                            <Upload {...avatarUploadInfo}>
                                                {" "}
                                                <Icon
                                                    type="edit"
                                                    title={formatMessage({
                                                        id:
                                                            GSAdminLocale.SchoolModelChangeAvatar
                                                    })}
                                                />{" "}
                                            </Upload>
                                        ]
                                }
                            >
                                <div className="custom-card">
                                    <label className="ant-form-item-label">
                                        {formatMessage({
                                            id: GSAdminLocale.SchoolModelAvatar
                                        })}
                                    </label>
                                </div>
                                <div className="custom-image">
                                    <Upload.Dragger {...avatarUploadInfo}>
                                        {!this.state.avatarImage && (
                                            <p className="school-avatar-text">
                                                {formatMessage({
                                                    id:
                                                        GSAdminLocale.SchoolModelDragLogo
                                                })}
                                            </p>
                                        )}
                                        {this.state.avatarImage && (
                                            <img
                                                alt=""
                                                className="school-avatar"
                                                {...avatarSrc}
                                            />
                                        )}
                                    </Upload.Dragger>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Form.Item>
                        <SubmitBtns
                            onCancel={this.props.onCancel}
                            submitTitle={GSAdminLocale.ButtonSubmit}
                        />
                        {/* <Button type='primary' htmlType='submit'>{ formatMessage({ id: GSAdminLocale.ButtonSubmit }) }</Button> */}
                        {this.props.deletable && (
                            <Button onClick={this.onDelete}>
                                {formatMessage({
                                    id: GSAdminLocale.ButtonDelete
                                })}
                            </Button>
                        )}
                    </Form.Item>
                    {this.renderDeleteModal()}
                </GLForm>
            </Spin>
        );
    }
}
